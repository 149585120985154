import classNames from 'classnames'
import React from 'react'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  errorBorder?: boolean
  noHover?: boolean
  notFull?: boolean
}

const Input = React.forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const { className, errorBorder, noHover, notFull, disabled, ...rest } = props

  return (
    <input
      ref={ref}
      className={classNames(
        'border',
        { 'border-red-1': errorBorder, 'border-basic-400': !errorBorder },
        { 'opacity-50': disabled },
        'bg-basic-200',
        'text-gray-700',
        !noHover && 'focus:border-accent',
        !noHover && 'focus:ring-accent',
        !noHover && 'focus:ring-1',
        'transition-all',
        'rounded-md',
        'block',
        !notFull && 'w-full',
        'px-4',
        'py-1',
        'text-base',
        'h-9',
        className
      )}
      disabled={disabled}
      {...rest}
    />
  )
})

export default Input
