import React from 'react'
import BasicLayout from '../BasicLayout'

const LoginFormLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <BasicLayout>
      <div className="bg-cover bg-center bg-bb-0/5 flex w-full min-h-full justify-center items-center">
        {children}
      </div>
    </BasicLayout>
  )
}

export default LoginFormLayout
