import React from 'react'
import Layout from '../Layout'
import Navbar from '../../widgets/Navbar'

type Props = React.PropsWithChildren<Record<string, unknown>>

const BasicLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout>
      <Navbar />
      <div className="content-area">
        <main className="flex-1 h-full overflow-y-auto">{children}</main>
      </div>
    </Layout>
  )
}

export default BasicLayout
