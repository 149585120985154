import React from 'react'
import BetablocksFormContainer from '../BetablocksFormContainer'

const LoginFormContainer: React.FC<
  React.PropsWithChildren<
    React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
      wide?: boolean
    }
  >
> = ({ children, wide, ...props }) => {
  return (
    <BetablocksFormContainer wide={wide}>
      <form
        className="relative bg-white mx-auto px-6 lg:px-16 py-12 w-full lg:shadow-2xl lg:shadow-bb-0/20 lg:max-w-2xl"
        {...props}
      >
        {children}
      </form>
    </BetablocksFormContainer>
  )
}

export default LoginFormContainer
