import classNames from 'classnames'
import React from 'react'

type Props = { className?: string; wide?: boolean }

const BetablocksFormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  wide,
  children,
}) => (
  <div
    className={classNames(
      'flex flex-col m-auto items-center px-5',
      wide ? 'max-w-3xl w-[675px]' : 'max-w-2xl w-[600px]',
      className
    )}
  >
    {children}
  </div>
)
export default BetablocksFormContainer
