import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import Text from '../Text'
import Tooltip from '../Tooltip'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title' | 'prefix'> & {
  errorText?: string
  inputClassName?: string
  inputLen?: number
  lengthLimit?: number
  noMargin?: boolean
  optional?: boolean
  prefix?: ReactNode
  suffix?: ReactNode
  hoverText?: string
  showInputLen?: boolean
  title?: string
  titleClassName?: string
  titleAccessory?: ReactNode
  subtitle?: string
}

const LabelledInput = React.forwardRef<HTMLInputElement, Props>(function LabelledInput(
  {
    children,
    title,
    optional,
    prefix,
    suffix,
    errorText,
    className,
    inputClassName,
    id,
    noMargin,
    hoverText,
    inputLen,
    lengthLimit,
    showInputLen = false,
    titleClassName,
    titleAccessory,
    disabled,
    subtitle,
    ...rest
  },
  ref
) {
  const { t } = useTranslation(['common'])

  return (
    <div className={classNames('flex', 'flex-col', !noMargin && 'mb-6', className)}>
      <div className="flex flex-row justify-between h-6">
        {title || optional || hoverText ? (
          <div className="flex flex-row gap-1 pb-2">
            {title && (
              <label
                htmlFor={id}
                className={classNames(
                  'capitalize block text-bg-10 font-bold font-heading text-xs',
                  { 'opacity-50': disabled },
                  titleClassName
                )}
              >
                {title}
              </label>
            )}
            {optional && (
              <label htmlFor={id} className="capitalize block text-bg-6 font-light text-xs">
                {t('optional')}
              </label>
            )}
            {hoverText && <Tooltip hoverText={hoverText} />}
          </div>
        ) : null}

        {!errorText && titleAccessory && <span className="self-end pb-2"> {titleAccessory} </span>}
        {errorText ? <div className="text-xs text-red-1 pb-2 font-bold">{errorText}</div> : false}
      </div>

      <div className="flex flex-row items-center gap-1">
        {prefix && <p className="text-bg-10 font-medium text-xs whitespace-nowrap">{prefix}</p>}
        <Input
          ref={ref}
          id={id}
          errorBorder={errorText ? true : false}
          className={inputClassName}
          disabled={disabled}
          {...rest}
        />
        {suffix && <p className="text-bg-10 font-medium text-xs whitespace-nowrap">{suffix}</p>}
      </div>

      {subtitle && (
        <p
          className={classNames('text-xs text-bg-8 pt-2', {
            'opacity-50': disabled,
          })}
        >
          {subtitle}
        </p>
      )}

      {showInputLen && (
        <Text className="flex flex-row-reverse text-xs" as="p" color="text-bg-7">
          {inputLen ? inputLen : 0}/{lengthLimit}
        </Text>
      )}

      {children}
    </div>
  )
})

export default LabelledInput
