/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { APISocialApp } from '@betablocks/shared/lib/api/auth'
import { handleError } from '@betablocks/shared/lib/api/base'
import * as EmailValidator from 'email-validator'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Apple from '../../../assets/icons/social/Apple'
import Fanvestor from '../../../assets/icons/social/Fanvestor'
import Google from '../../../assets/icons/social/Google'
import Office from '../../../assets/icons/social/Office'
import useApi from '../../../hooks/useApi'
import { setTokenCookie } from '../../../utils/cookie'
import Button from '../../atoms/Button'
import DividerWithText from '../../atoms/DividerWithText'
import LabelledInput from '../../atoms/LabelledInput'
import Text from '../../atoms/Text'
import LoginFormContainer from '../../widgets/LoginFormContainer'

type Props = {
  continueLink: string
  socialApps: APISocialApp[]
}

type Inputs = {
  email: string
  password: string
  code: string
}

type ErrorType = {
  detail?: string
}

const LoginForm: React.FC<Props> = ({ continueLink, socialApps }) => {
  const { t } = useTranslation(['login-page', 'form-errors'])
  const router = useRouter()
  const api = useApi()

  const [error, setError] = useState<ErrorType>({})
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (data) => {
      let token: string

      setError({})
      setIsLoading(true)

      try {
        ;({ token } = await api.auth.login(data))
      } catch (e) {
        const err = await handleError(e)
        const { ephemeralToken } = err.detail

        // Go to MFA token input page.
        if (ephemeralToken) {
          router.push(`/login/mfa/?token=${ephemeralToken}&next=${continueLink}`)
          return
        }

        setError(err.detail)
        return
      } finally {
        setIsLoading(false)
      }

      setTokenCookie(token)

      router.push(continueLink)
    },
    [api.auth, continueLink, router]
  )

  const renderLogo = (provider: APISocialApp['provider']): React.ReactNode | undefined => {
    switch (provider) {
      case 'apple':
        return <Apple />
      case 'google':
        return <Google />
      case 'microsoft':
        return <Office />
      case 'fanvestor':
        return <Fanvestor />
      default:
        return undefined
    }
  }

  return (
    <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Text as="h3" color="text-indigo-1" className="pb-10 font-bold text-center">
        {t('landing.title')}
      </Text>

      {!!error && !!error.detail && (
        <div className="content-center border border-red-1 text-center py-3 mb-4">
          {Array.from(Object.values(error)).map((val, index) => (
            <div className="text-red-1 text-xs font-bold" key={index}>
              {val}
            </div>
          ))}
        </div>
      )}

      <LabelledInput
        title={t('form.email')}
        className="pb-1"
        inputClassName="h-10"
        placeholder={t('form.email')}
        {...register('email', {
          required: `${t('form.required')}`,
          validate: (value) =>
            !EmailValidator.validate(value) ? `${t('form.invalidEmail')}` : undefined,
        })}
        disabled={isLoading}
        errorText={errors.email ? errors.email.message : undefined}
        tabIndex={1}
      />

      <LabelledInput
        title={t('form.password')}
        className="pb-1"
        inputClassName="h-10"
        type="password"
        placeholder="••••••••••••••••"
        {...register('password', {
          required: `${t('form.required')}`,
        })}
        titleAccessory={
          <span tabIndex={3} className="flex text-[#9314F5] hover:underline capitalize text-xs">
            <Link href="/recover-password">{t('form.forgotPassword')}</Link>
          </span>
        }
        disabled={isLoading}
        errorText={errors.password ? errors.password.message : undefined}
        tabIndex={2}
      />

      <div className="mb-6 lg:flex justify-center">
        <Button disabled={isLoading} tabIndex={4} box variant="primaryBlue" type="submit">
          {t('button.signIn')}
        </Button>
      </div>

      <Text className="flex-1 text-center" as="p" color="text-indigo-1">
        {t('form.dontHaveAccount')}{' '}
        <Link href="/signup" passHref>
          <a tabIndex={5} className="text-[#9314F5] hover:underline cursor-pointer">
            {t('form.createAccount')}
          </a>
        </Link>
      </Text>

      <DividerWithText>{t('divider.or', { ns: 'common' })}</DividerWithText>

      <div className="flex flex-row flex-wrap gap-2.5 justify-center">
        {socialApps.map((provider) => (
          <Button
            key={provider.provider}
            variant="default"
            outline
            icon={renderLogo(provider.provider)}
            iconAlign="left"
            href={`/api/v1/auth/social/${provider.provider}/login/`}
            onClick={() => setIsLoading(true)}
            disabled={isLoading}
            locale={false}
          >
            {provider.name}
          </Button>
        ))}
      </div>
    </LoginFormContainer>
  )
}
export default LoginForm
