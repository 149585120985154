import { APISocialApp } from '@betablocks/shared/lib/api/auth'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from '../../forms/LoginForm'
import LoginFormLayout from '../../layouts/LoginFormLayout'

type Props = {
  socialApps: APISocialApp[]
}

const LoginPage: React.FC<Props> = ({ socialApps }) => {
  const { t } = useTranslation('login-page')
  const router = useRouter()

  const continueLink = (router.query.next as string) ?? '/'

  return (
    <LoginFormLayout>
      <Head>
        <title>{t('landing.title')}</title>
      </Head>

      <LoginForm continueLink={continueLink} socialApps={socialApps} />
    </LoginFormLayout>
  )
}
export default LoginPage
