import API from '@betablocks/shared/lib/api'
import { APISocialApp } from '@betablocks/shared/lib/api/auth'
import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { requireUnauthenticated } from '../../auth/requireUnauthenticated'
import LoginPage from '../../components/pages/LoginPage'

type Props = {
  socialApps: APISocialApp[]
}

const Login: React.FC<Props> = ({ socialApps }) => {
  return <LoginPage socialApps={socialApps} />
}

export const getServerSideProps: GetServerSideProps = requireUnauthenticated(async (context) => {
  const { locale } = context

  const api = new API({ req: context.req })
  const socialApps = await api.auth.socialApps()

  return {
    props: {
      socialApps,
      ...(await serverSideTranslations(locale, ['common', 'navbar', 'form-errors', 'login-page'])),
    },
  }
})

export default Login
